/**
 * changeStepper: Action creator to change the stepper value.
 */
export const changeStepper = (data: number) => {
  return {
    type: "CHANGE_STEP",
    payload: data,
  };
};

/**
 * setSkipConfigureStep: Action creator to set the state for skipping the configure step.
 */
export const setSkipConfigureStep = (data: any) => {
  return {
    type: "SKIP_CONFIGURE_STEP",
    payload: data,
  };
};

/**
 * setSkipPeripheralStep: Action creator to set the state for skipping the peripheral step.
 */
export const setSkipPeripheralStep = (data: any) => {
  return {
    type: "SKIP_PERIPHERAL_STEP",
    payload: data,
  };
};

/**
 * skipOperatorLayout: Action creator to set the state for skipping the operator layout step.
 */
export const skipOperatorLayoutStep = (data: any) => {
  return {
    type: "SKIP_OPERATOR_LAYOUT_STEP",
    payload: data,
  };
};



/**
 * setIsLoading: Action creator to set the loading state.
 */
export const setIsLoading = (data: boolean) => {
  return {
    type: "SET_LOADING_STATE",
    payload: data,
  };
};

/**
 * setIsLoadingTranslucent: Action creator to set the translucent loading state.
 */
export const setIsLoadingTranslucent = (data: boolean) => {
  return {
    type: "SET_LOADING_STATE_TRANSLUCENT",
    payload: data,
  };
};

/**
 * setIsLoadingBlock: Action creator to set the block loading state.
 */
export const setIsLoadingBlock = (data: boolean) => {
  return {
    type: "SET_LOADING_STATE_BLOCK",
    payload: data,
  };
};

// Workstation actions

/**
 * setWorkstationData: Action creator to set the workstation form data.
 */
export const setWorkstationData = (data: object) => {
  return {
    type: "SET_FORM_DATA",
    payload: data,
  };
};

/**
 * setAddStationNextButtonDisable: Action creator to set the state for disabling the add station next button.
 */
export const setAddStationNextButtonDisable = (data: boolean) => {
  return {
    type: "SET_WORKSTATION_BUTTON_STATE",
    payload: data,
  };
};

/**
 * setInspectionStationData: Action creator to set the inspection station data.
 */
export const setInspectionStationData = (data: any = []) => {
  return {
    type: "SET_INSPECTION_STATION_DATA",
    payload: data,
  };
};

/**
 * setWorkstationType: Action creator to set the workstation type.
 */
export const setWorkstationType = (data: any) => {
  return {
    type: "SET_WORKSTATION_TYPE",
    payload: data,
  };
};

// Usecase actions

/**
 * setUsecaseData: Action creator to set the use case data.
 */
export const setUsecaseData = (data: any = []) => {
  return {
    type: "SET_USECASE_LIST_DATA",
    payload: data,
  };
};

/**
 * setUsecaseFormData: Action creator to set the use case form data.
 */
export const setUsecaseFormData = (data: any) => {
  return {
    type: "SET_USECASE_FORM_DATA",
    payload: data,
  };
};

/**
 * setAddUsecaseNextButtonDisable: Action creator to set the state for disabling the add use case next button.
 */
export const setAddUsecaseNextButtonDisable = (data: boolean) => {
  return {
    type: "SET_USECASE_BUTTON_STATE",
    payload: data,
  };
};

/**
 * changeChooseTagStepper: Action creator to change the choose tag stepper value.
 */
export const changeChooseTagStepper = (data: number) => {
  return {
    type: "CHANGE_CHOOSE_TAG_STEP",
    payload: data,
  };
};

/**
 * setTagId: Action creator to set the tag ID.
 */
export const setTagId = (data: number) => {
  return {
    type: "SET_PARENT_ID",
    payload: data,
  };
};

// Part actions

/**
 * setPartFormData: Action creator to set the part form data.
 */
export const setPartFormData = (data: object) => {
  return {
    type: "SET_PART_FORM_DATA",
    payload: data,
  };
};

/**
 * setAddPartNextButtonDisable: Action creator to set the state for disabling the add part next button.
 */
export const setAddPartNextButtonDisable = (data: boolean) => {
  return {
    type: "SET_PART_BUTTON_STATE",
    payload: data,
  };
};

/**
 * setPartData: Action creator to set the part data.
 */
export const setPartData = (data: any = []) => {
  return {
    type: "SET_PART_LIST_DATA",
    payload: data,
  };
};

/**
 * setPartUnfilteredData: Action creator to set the unfiltered part data.
 */
export const setPartUnfilteredData = (data: any = []) => {
  return {
    type: "SET_PART_UNFILTERED_LIST_DATA",
    payload: data,
  };
};

/**
 * setSelectedPart: Action creator to set the selected part.
 */
export const setSelectedPart = (data: string) => {
  return {
    type: "SET_SELECTED_PART",
    payload: data,
  };
};

/**
 * setAddPartWithoutEdge: Action creator to set the state for adding a part without an edge.
 */
export const setAddPartWithoutEdge = (data: boolean) => {
  return {
    type: "SET_ADD_PART_WITHOUT_EDGE",
    payload: data,
  };
};

// Data Management Actions

/**
 * setManagePartData: Action creator to set the manage part data.
 */
export const setManagePartData = (data: any) => {
  return {
    type: "SET_PART_DATA_LIST_DATA",
    payload: data,
  };
};

/**
 * setImportFormData: Action creator to set the import form data.
 */
export const setImportFormData = (data: any) => {
  return {
    type: "SET_IMPORT_FORM_DATA",
    payload: data,
  };
};

/**
 * setImportFormNextButtonDisable: Action creator to set the state for disabling the import form next button.
 */
export const setImportFormNextButtonDisable = (data: boolean) => {
  return {
    type: "SET_IMPORT_FORM_BUTTON_STATE",
    payload: data,
  };
};

/**
 * setImportIncludeAnnotation: Action creator to set the state for including annotations in the import.
 */
export const setImportIncludeAnnotation = (data: any) => {
  return {
    type: "SET_IMPORT_INCLUDE_ANNOTATION",
    payload: data,
  };
};

// Operator builder selected Component Action

/**
 * setSelectedOperatorBuilderComponent: Action creator to set the selected operator builder component.
 */
export const setSelectedOperatorBuilderComponent = (data: any) => {
  return {
    type: "SET_OPERATOR_BUILDER_COMPONENT",
    payload: data,
  };
};

/**
 * setOnHoverOperatorBuilderComponent: Action creator to set the on-hover operator builder component.
 */
export const setOnHoverOperatorBuilderComponent = (data: any) => {
  return {
    type: "SET_ON_HOVER_OPERATOR_BUILDER_COMPONENT",
    payload: data,
  };
};

/**
 * setAddComponentToOperatorPanel: Action creator to add a component to the operator panel.
 */
export const setAddComponentToOperatorPanel = (data: any) => {
  return {
    type: "ADD_COMPONENT_TO_OPERATOR_PANEL",
    payload: data,
  };
};

/**
 * setOperatorBuilderAvailedComponents: Action creator to set the available components in the operator builder.
 */
export const setOperatorBuilderAvailedComponents = (data: any) => {
  return {
    type: "SET_OPERATOR_BUILDER_AVAILED_COMPONENTS",
    payload: data,
  };
};

/**
 * setOperatorBuilderPreviewModal: Action creator to set the operator builder preview modal state.
 */
export const setOperatorBuilderPreviewModal = (data: any) => {
  return {
    type: "SET_PREVIEW_MODAL",
    payload: data,
  };
};

/**
 * setOperatorBuilderPreviewDetails: Action creator to set the operator builder preview details.
 */
export const setOperatorBuilderPreviewDetails = (data: any) => {
  return {
    type: "SET_PREVIEW_MODAL_DETAILS",
    payload: data,
  };
};

/**
 * setCurrentOperatorBuilderInitialState: Action creator to set the initial state of the current operator builder.
 */
export const setCurrentOperatorBuilderInitialState = (data: any) => {
  return {
    type: "SET_CURRENT_OPERATOR_BUILDER_INTITAL_STATE",
    payload: data,
  };
};

/**
 * setEditOperatorBuilderConfigPayload: Action creator to set the payload for editing the operator builder configuration.
 */
export const setEditOperatorBuilderConfigPayload = (data: any) => {
  return {
    type: "SET_EDIT_OPERATOR_BUILDER_CONFIG_PAYLOAD",
    payload: data,
  };
};

/**
 * setTrackHistroyOfOperatorPanelBuilder: Action creator to set the track history of the operator panel builder.
 */
export const setTrackHistroyOfOperatorPanelBuilder = (data: any) => {
  return {
    type: "SET_TRACK_HISTORY_OF_OPERATOR_PANEL_BUILDER",
    payload: data,
  };
};

// Recipe Reducers

/**
 * setRecipeData: Action creator to set the recipe data.
 */
export const setRecipeData = (data: any) => {
  return {
    type: "SET_RECIPE_LIST_DATA",
    payload: data,
  };
};

/**
 * setRecipeFormData: Action creator to set the recipe form data.
 */
export const setRecipeFormData = (data: any) => {
  return {
    type: "SET_RECIPE_FORM_DATA",
    payload: data,
  };
};

/**
 * setAddRecipeNextButtonDisable: Action creator to set the state for disabling the add recipe next button.
 */
export const setAddRecipeNextButtonDisable = (data: boolean) => {
  return {
    type: "SET_RECIPE_BUTTON_STATE",
    payload: data,
  };
};

/**
 * setAddDataModal: Action creator to set the state for the add data modal.
 */
export const setAddDataModal = (data: boolean) => {
  return {
    type: "SET_ADD_DATA_MODAL_STATE",
    payload: data,
  };
};

//reports actions
/**
 * setReportsType: Action creator to set the report type.
 */
export const setReportsType = (data: any) => {
  return {
    type: "SET_REPORT_TYPE",
    payload: data,
  };
};

/**
 * setReportPayload: Action creator to set the report payload.
 */
export const setReportPayload = (data: any) => {
  return {
    type: "SET_REPORT_PAYLOAD",
    payload: data,
  };
};

/**
 * setReportResponse: Action creator to set the report response.
 */
export const setReportResponse = (data: any) => {
  return {
    type: "SET_REPORT_RESPONSE",
    payload: data,
  };
};

/**
 * setCallReportsAPI: Action creator to set the state for calling the reports API.
 */
export const setCallReportsAPI = (data: boolean) => {
  return {
    type: "SET_CALL_REPORTS_API_STATE",
    payload: data,
  };
};

/**
 * setReportImageVerifcation: Action creator to set the report image verification state.
 */
export const setReportImageVerifcation = (data: boolean) => {
  return {
    type: "SET_REPORT_IMAGE_VERIFICATION",
    payload: data,
  };
};

//update sidebar

/**
 * setCallSidebarAPI: Action creator to set the state for calling the sidebar API.
 */

export const setCallSidebarAPI = (data: any) => {
  return {
    type: "SET_SIDE_BAR_API",
    payload: data,
  };
};

//training actions

/**
 * setTrainingStatus: Action creator to set the training status.
 */
export const setTrainingStatus = (data: any) => {
  return {
    type: "SET_TRAINING_STATUS",
    payload: data,
  };
};


/**
 * setTrainingOrRetrainingStatus: Action creator to set the training or retraining status.
 * This action creator is used to set the status of the training or retraining process.
 * The payload should be a boolean indicating whether the training or retraining is in progress.
 */
export const setTrainingOrRetrainingStatus = (data: any) => {
  return {
    type: "SET_TRAINING_OR_RETRAINING_STATUS",
    payload: data,
  };
}

/**
 * setAugmentationData: Action creator to set the augmentation data.
 */
export const setAugmentationData = (data: any) => {
  return {
    type: "SET_AUGMENTATION_DATA",
    payload: data,
  };
};

/**
 * setIsAugmented: Action creator to set the augmentation state.
 */
export const setIsAugmented = (data: any) => {
  return {
    type: "SET_IS_AUGMENTED",
    payload: data,
  };
};

// user management actions

/**
 * setUserManagementData: Action creator to set the user management data.
 */
export const setUserManagementData = (data: any) => {
  return {
    type: "SET_USER_MANAGEMENT_DATA",
    payload: data,
  };
};

/**
 * setUserManagementButton: Action creator to set the user management button state.
 */

export const setUserManagementButton = (data: any) => {
  return {
    type: "SET_USER_MANAGEMENT_BUTTON",
    payload: data,
  };
};

/**
 * setAppManagementData: Action creator to set the app management data.
 */
export const setAppManagementData = (data: any) => {
  return {
    type: "SET_APP_MANAGEMENT_DATA",
    payload: data,
  };
};

/**
 * setPermissionManagementData: Action creator to set the permission management data.
 */
export const setPermissionManagementData = (data: any) => {
  return {
    type: "SET_PERMISSION_MANAGEMENT_DATA",
    payload: data,
  };
};

/**
 * setRoleManagementData: Action creator to set the role management data.
 */
export const setRoleManagementData = (data: any) => {
  return {
    type: "SET_ROLE_MANAGEMENT_DATA",
    payload: data,
  };
};

// tag management actions

/**
 * setTagData: Action creator to set the tag data.
 */
export const setTagData = (data: any) => {
  return {
    type: "SET_TAG_DATA",
    payload: data,
  };
};

/**
 * setCallGetTag: Action creator to set the state for calling the get tag API.
 */

export const setCallGetTag = (data: any) => {
  return {
    type: "SET_CALL_GET_TAG",
    payload: data,
  };
};

export const setProcessName = (data: any) => {
  return {
    type: "SET_PROCESS_NAME",
    payload: data,
  };
};

const initialState = "";


const processNameReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_PROCESS_NAME":
      return action?.payload;
      break;
    default:
      return state;
  }
};

export default processNameReducer;

import Axios from "axios";
import { t } from "i18next";
import { livisAlert } from "./alert";
import { LIVIS_API_BASE_URL } from "../config";
import {
  getAlertStatus,
  getLivisToken,
  getLivisUserInfo,
  getRefreshToken,
  removeAlertStatus,
  resetLivisUserInfo,
  setLivisUserInfo,
} from "./storage";


let refreshTokenPromise: Promise<boolean> | null = null;
let suppress403Toast = false; // State to track suppression of 403 toasts
let previousURL = ""; // Declare previousURL properly

// Function to refresh the access token
const refreshLivisToken = async () => {

  const refresh_token = getRefreshToken();
  if (!refresh_token) {
    resetLivisUserInfo(); // Logout if no refresh token
    return false;
  }

  if (!refreshTokenPromise) {
    refreshTokenPromise = (async () => {
      try {
        const response = await fetch(`${LIVIS_API_BASE_URL}authorization/refresh_token/`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({ refresh_token }),
        });

        if (response.status === 401) {
          resetLivisUserInfo();
          window.location.href = "/";
          refreshTokenPromise = null;
          return false;
        }

        const data = await response.json();
        const user_info = data;
        const currentUserInfo = JSON.parse(getLivisUserInfo() || '{}');
        const new_user_info = {
          ...currentUserInfo,
          access_token: user_info?.data?.access_token,
          refresh_token: user_info?.data?.refresh_token,
        };

        if (new_user_info) {
          setLivisUserInfo(JSON.stringify(new_user_info));
          refreshTokenPromise = null;
          return true;
        } else {
          resetLivisUserInfo();
          refreshTokenPromise = null;
          return false;
        }
      } catch (error) {
        console.error(error);
        refreshTokenPromise = null;
        return false;
      }
    })();
  }

  return refreshTokenPromise;
};

// Request interceptor to add Authorization headers
function authRequestInterceptor(config: any) {
  const token = getLivisToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = "application/json";
  config.headers["Content-Type"] = "application/json";
  return config;
}

// List of restricted URLs where no alerts should be shown
const restricted_url = [
  "parts/check_part_uniqueness/",
  "authorization/login/",
  "workstations/check_unique_workstation_name/",
  "usecase/check_usecase_uniqueness/",
  "parameters/get_all_parameters/"
];

export const axios = Axios.create({
  baseURL:
    window.location.pathname === '/app/operator/report' || window.location.pathname === '/app/operator/home'
      ? 'http://localhost:9000/'
      : LIVIS_API_BASE_URL,
});

// Adding request interceptor
axios.interceptors.request.use(authRequestInterceptor);

// Adding response interceptor
axios.interceptors.response.use(
  (response) => {
    suppress403Toast = false; // Reset suppression on any successful response
    return response;
  },
  async (error) => {
    if (error?.message === "Network Error") {
      livisAlert(error?.message, "error");
      return null;
    }

    const status = error?.response?.status;
    const err_msg = t(`${error?.response?.data?.message}`);
    const no_alert_status = getAlertStatus();
    const restrict_url = restricted_url.includes(error?.config?.url);

    if (status === 401) {
      const access_token = await refreshLivisToken();
      if (access_token) {
        error.config.headers["authorization"] = `Bearer ${getLivisToken()}`;
        return axios(error.config);
      } else {
        return error.response;
      }
    }

    // Handle 403 errors with toast suppression logic
    if (status === 403) {
      const currentURL = window.location.pathname;
      if (previousURL !== currentURL) {
        suppress403Toast = false; // Reset suppression if the page URL changes
      }
      if (!suppress403Toast) {
        livisAlert(`${t("User does not have permission to perform this action")}`, "warning");
        suppress403Toast = true; // Suppress subsequent 403 toasts until reset
      }
      previousURL = currentURL;
    } else if (status === 200) {
      suppress403Toast = false; // Reset suppression on a 200 status
    }

    if (!no_alert_status && !restrict_url) {
      switch (status) {
        case 400:
        case 403:
          livisAlert(`${t("User does not have permission to perform this action")}`, "warning");
          break;
        case 404:
          livisAlert(`${t("API Not Found")}`, "error");          
          break;
        case 500:
          livisAlert(`${t("Internal server error")}`, "error");
          break;
        default:
          livisAlert(`${t("Unknown Error")}`, "error");
      }
    }

    removeAlertStatus();
    return error.response;
  }
);

